import cookie from "js-cookie";
import { useState, useEffect } from "react";
import { type State, stateSchema, initialState } from "./State";
import { usePrevious } from "../../../hooks";
import { Cms } from "@swo/shared";

export function useToolbarState() {
  const KEY = Cms.CookieNames.state;

  const [state, setState] = useState<State | null>(readState());
  const previousState = usePrevious(state);

  function readState(): State | null {
    return null;
    // try {
    //   const s = cookie.get(KEY);

    //   if (!s || s === "null") {
    //     return null;
    //   }

    //   return s ? stateSchema.parse(JSON.parse(s)) : null;
    // } catch (e) {
    //   return null;
    // }
  }

  // useEffect(() => {
  //   cookie.set(KEY, JSON.stringify(state), {
  //     expires: 365,
  //   });

  //   if (previousState && state && previousState.opened && previousState.errorsStrategy !== state.errorsStrategy) {
  //     window.location.reload();
  //   }
  // }, [state]);

  return { state, setState };
}
