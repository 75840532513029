import { CSS_COLORS } from "../../../../../styles/styles-css-variables";
import { ReactTFC } from "../../../../../utils/fp/missing-ts-parts";

import { css } from "@emotion/react";

import { LineItemInfo } from "@swo/storefront";
import { option } from "fp-ts";
import { NoImageSvg, TrashSvg } from "../../../../../assets";
import { CSS_MQ } from "../../../../../styles/breakpoints";
import { fontColor, FONTS, fontSize } from "../../../../../styles/fonts";
import { ContentfulImage } from "../../../../_shared-components/image-related/ContentfulImage";
import { SwarovskiLink } from "../../../../_shared-components/ui-elements/SwarovskiLink";
import { LinkOrSpan } from "../../../../ProductsCarouselComponent/LinkOrSpan";
import { SwarovskiButton } from "../../../../_shared-components/ui-elements/SwarovskiButton";
import { AttributesTag } from "../../../../_shared-components/ui-elements/AttributesTags/AttributesTag";
import { ContentfulImage2 } from "../../../../_shared-components/image-related/ContentfulImage2";
import { useGeneralTranslationsContext } from "../../../../../global/hooks/use-general-translations-context";

export const ShoppingCartDropdownCartElement: ReactTFC<{
  name: string;
  attributes: Array<string>;
  price: {
    current: string;
    previous: string | undefined;
  };
  lineItemProductId: string;
  lineItemVariantId: number;
  onLineItemRemovalRequested: (() => Promise<void>) | undefined;
  lineItemInfo: LineItemInfo;
  quantity: number;
}> = props => {
  const link: string | undefined = option.toUndefined(props.lineItemInfo.link);
  const noImage = useGeneralTranslationsContext()("noImage");

  const ImageOrFallback = props.lineItemInfo.image ? (
    <ContentfulImage2
      contentfulUrl={props.lineItemInfo.image.url}
      withTransparency={false}
      cropping={undefined}
      alt={props.lineItemInfo.image.description || props.lineItemInfo.image.title || undefined}
      sizes={"100px"}
      css={css`
        width: 100%;
        height: 100%;
        object-fit: contain;
        aspect-ratio: 4/3;
        display: flex;
      `}
    />
  ) : (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;

        color: ${CSS_COLORS.GrayDark2024};
        width: 50px;
        height: 50px;
      `}
    >
      <NoImageSvg strokeWidth={3} aria-label={noImage.value} />
    </div>
  );

  const QuantityLabel = (
    <span
      css={[
        FONTS.SwarovskiFutura300,
        fontSize.x2,
        css`
          display: inline-flex;
          align-items: center;
        `,
      ]}
    >
      {`×${props.quantity}`}
    </span>
  );

  return (
    <div css={gridStyles}>
      <div
        css={css`
          aspect-ratio: 1/1;

          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {link ? (
          <SwarovskiLink link={link} isInternal={true} scrollToTop={true} styled={"no-decoration"}>
            {ImageOrFallback}
          </SwarovskiLink>
        ) : (
          <span>{ImageOrFallback}</span>
        )}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <span>
          <LinkOrSpan to={{ pathname: link }} attachStyles={nameStyles} state={{ scrollToTop: true }}>
            <span>{props.name}</span>
          </LinkOrSpan>

          {props.attributes.length > 0 && (
            <span
              css={css`
                display: flex;
                gap: 4px;
                margin-top: 2px;
                margin-bottom: 4px;
              `}
            >
              {props.attributes.map(a => (
                <AttributesTag key={a} label={a} size="small" />
              ))}
            </span>
          )}
        </span>

        <div className={"u-d-flex u-fd-column"}>
          {props.price.previous && (
            <span
              className={"u-fw-300"}
              css={[
                priceStyles,
                FONTS.SwarovskiFutura300,
                css`
                  text-decoration: line-through;
                `,
              ]}
            >
              {props.price.previous}
            </span>
          )}

          <span
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 4px;
            `}
          >
            <span css={priceStyles}>{props.price.current}</span>

            {QuantityLabel}
          </span>
        </div>
      </div>

      {props.onLineItemRemovalRequested && (
        <SwarovskiButton
          as="button"
          onClickWithPromise={props.onLineItemRemovalRequested}
          variant="unstyled"
          css={css`
            align-self: start;
          `}
        >
          <TrashSvg css={deleteIconStyles} />
        </SwarovskiButton>
      )}
    </div>
  );
};

const nameStyles = [
  FONTS.SwarovskiFutura500,
  fontColor.black,
  fontSize.x2,
  css`
    display: inline-flex;
    align-items: center;

    text-decoration: none;
  `,
];

const priceStyles = [FONTS.SwarovskiFutura300, fontColor.black, fontSize.x2];

const deleteIconStyles = css`
  width: 16px;
  height: 16px;

  display: block;
  margin: 0 auto;
`;

const gridStyles = css`
  display: grid;

  grid-template-columns: 100px 1fr auto;
  grid-column-gap: 10px;
  ${CSS_MQ.atLeastMd} {
    grid-column-gap: 10px;
    grid-template-columns: 100px 1fr auto;
  }
`;
