import React from "react";
import { css } from "@emotion/react";
import { Link } from "react-router";
import { CSS_MQ } from "../../../styles/breakpoints";
import { HeaderNavigationContentStoriesList } from "@swo/storefront";
import { FONTS, fontSize } from "../../../styles/fonts";
import { EXPERIMENTAL_TextWithProductNameRenderer } from "../../../domain/swarovski-optik/products/product-name/product-name-renderer";

export const StoryComponent: React.FC<{
  link: string;
  primaryHeadline: string;
  secondaryHeadline: string | undefined;
  additionalText: string;
}> = ({ link, primaryHeadline, secondaryHeadline, additionalText }) => {
  return (
    <Link
      to={{ pathname: link }}
      state={{ scrollToTop: true }}
      css={css`
        color: inherit;
        text-decoration: none;
        &:hover {
          h2 {
            text-decoration: underline;
          }
        }
      `}
    >
      {secondaryHeadline && (
        <h3
          className="u-uppercase"
          css={[
            FONTS.SwarovskiFutura300,
            fontSize.x2,
            css`
              margin-top: 0;
              margin-bottom: 4px;
            `,
          ]}
        >
          <EXPERIMENTAL_TextWithProductNameRenderer text={secondaryHeadline} />
        </h3>
      )}

      <h2
        className="u-uppercase u-text-balance"
        css={[
          FONTS.SwarovskiFutura500,
          fontSize.x25,
          css`
            margin: 0;
          `,
        ]}
      >
        <EXPERIMENTAL_TextWithProductNameRenderer text={primaryHeadline} />
      </h2>

      <span css={[FONTS.SwarovskiFutura300, fontSize.x25]}>{additionalText}</span>
    </Link>
  );
};
