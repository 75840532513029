import React from "react";
import { css } from "@emotion/react";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { fontSize } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { NewLabel } from "../../_shared-components/new/new-label";
import { Node } from "./Links";

export const LinkNode: React.FC<Node> = ({ link, isNew }) => {
  return (
    <SwarovskiLink
      link={link.data}
      isInternal={link.type === "Internal Link"}
      styled="no-decoration"
      className="u-relative"
      attachStyles={[
        fontSize.x25,
        css`
          color: ${CSS_COLORS.Black};

          &:hover {
            text-decoration: underline;
          }
        `,
      ]}
    >
      {link.label}

      {isNew && (
        <NewLabel
          inline={false}
          css={css`
            font-size: 0.5em;
          `}
        />
      )}
    </SwarovskiLink>
  );
};
