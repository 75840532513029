import { useCallback } from "react";
import { useStore } from "../store/hooks/use-store";

/*
  This serves as a primary Translations utility
  
  For now, it's a compat-layer between string-valued-translations and ones wrapped in TranslatedValue object.

  In future, this should handle displaying translation overlays for editors, etc.
 */

export function useContentfulLink() {
  const { state } = useStore();
  const { space, environment } = state.config.contentful;

  return useCallback(
    (entryId: string) => `https://app.contentful.com/spaces/${space}/environments/${environment}/entries/${entryId}`,
    [space, environment],
  );
}
