import { css } from "@emotion/react";
import { NewHeaderData, SwarovskiOptikLocaleFrontend } from "@swo/storefront";
import React from "react";

import { FONTS, fontSize } from "../../../styles/fonts";

import { LanguagePickerComponent } from "../../LanguagePickerComponent/LanguagePickerComponent";
import { LanguagePickerCountryFlag } from "../../LanguagePickerComponent/LanguagePickerCountryFlag";
import { getLocalisedLanguageLabel } from "../../LanguagePickerComponent/getLocalisedLabel";
import { HeaderT } from "../HeaderT";
import { T } from "../../T";

export type LanguagePickerPropsT = {
  languagePicker: NewHeaderData["otherData__legacy"]["languagePicker"];
  t: HeaderT;
  locale: SwarovskiOptikLocaleFrontend;
  compact?: boolean;
};

export const LanguagePickerLayout: React.FC<LanguagePickerPropsT> = ({
  languagePicker: { internationalData, countryBasedData },
  locale,
  t,
  compact = false,
}) => {
  return (
    <div>
      <div
        className="u-flex u-items-center u-gap-3"
        css={css`
          margin-bottom: 24px;
        `}
      >
        <span className="u-m-0 u-flex u-items-center u-uppercase" css={[fontSize.x2, FONTS.SwarovskiFutura700]}>
          <T>{t("yourLanguageHeadline")}</T>:{" "}
        </span>

        <div className="u-flex u-items-center u-gap-2 u-mb-1 u-underline">
          {locale.country === "INTERNATIONAL" ? null : (
            <LanguagePickerCountryFlag
              countryIsoCode={locale.country.isoCode}
              css={css`
                color: inherit;
              `}
              className="size-small"
            />
          )}

          <span>
            {getLocalisedLanguageLabel({
              languageIsoCode: locale.language.isoCode,
            }) ?? locale.language.label}
          </span>
        </div>
      </div>

      <LanguagePickerComponent
        internationalData={internationalData}
        countryBasedData={countryBasedData}
        otherCountriesLabel={t("otherCountriesLabel")}
        compact={compact}
      />
    </div>
  );
};
