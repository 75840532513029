import { Cms } from "@swo/shared";
import type { z } from "zod";

export type State = Cms.State | null;

export const stateSchema = Cms.stateSchema;

export type ErrorsStrategy = z.infer<typeof stateSchema.shape.errorsStrategy>;
export const errorsStrategies: Array<ErrorsStrategy> = stateSchema.shape.errorsStrategy._def.options.map(_ => _.value);

export const initialState: NonNullable<State> = {
  opened: true,
  componentLinks: false,
  translationHints: false,
  errorsStrategy: "fine-grained",
};
