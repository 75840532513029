import React from "react";
import { ImageWithFocalPointType, Link } from "@swo/shared";
import { css } from "@emotion/react";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { fontSize } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";
import { ImageWithFocalPoint } from "../../_shared-components/image-related/ImageWithFocalPoint";
import { HeaderCustomLinksNavT } from "@swo/storefront";
import { useGeneralTranslationsContext } from "../../../global/hooks/use-general-translations-context";
import { HeadlineWithNew } from "./HeadlineWithNew";

type Props = {
  links: HeaderCustomLinksNavT["links"];
};

export const LinksWithImages = React.forwardRef<HTMLUListElement, Props>(({ links }, ref) => {
  return (
    <ul
      ref={ref}
      className="u-flex u-flex-col u-mb-2"
      css={[
        css`
          gap: 30px;
          ${CSS_MQ.atLeastMd} {
            gap: 40px;
          }
        `,
      ]}
    >
      {links.map(({ link, image, isNew }) => (
        <li key={link.label}>
          <span css={[fontSize.x25]}>
            <SwarovskiLink
              link={link.data}
              isInternal={link.type === "Internal Link"}
              styled="no-decoration"
              className="u-flex u-flex-col u-gap-1 u-relative"
              prefetch="intent"
              attachStyles={[
                fontSize.x25,
                css`
                  color: ${CSS_COLORS.Black};

                  &:hover {
                    text-decoration: underline;
                  }
                `,
              ]}
            >
              {image && (
                <ImageWithFocalPoint
                  style={{ "--ar": image.image.width / image.image.height } as React.CSSProperties}
                  image={image.image}
                  focalPoint={image.focalPoint}
                  requestedWidth={500}
                  attachPictureStyles={css`
                    position: relative;

                    display: block;
                    aspect-ratio: var(--ar);

                    ${CSS_MQ.atMostSm} {
                      margin-left: -40px;
                      width: calc(100% + 60px);
                    }
                  `}
                />
              )}

              <HeadlineWithNew isNew={isNew} headline={link.label} className="u-mt-1" />
            </SwarovskiLink>
          </span>
        </li>
      ))}
    </ul>
  );
});
