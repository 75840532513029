import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import { ContentfulImageProps } from "./ContentfulImage";
import { css, SerializedStyles } from "@emotion/react";
import { transformImage, wrapToHtmlSourceElements } from "./transformImage";
import React from "react";

export const ImgWithObjectPosition: ReactTFC<{
  focalPointCssStr: string;
  contentfulLink: ContentfulImageProps["contentfulLink"];
  lazyLoaded?: "lazy" | "eager";
  css?: SerializedStyles;
  attachPictureStyles?: SerializedStyles;
  alt?: string;
  style?: React.CSSProperties;
}> = props => {
  const {
    contentfulLink,
    lazyLoaded,
    focalPointCssStr,
    css: attachStyles,
    alt,
    attachPictureStyles,
    style,
    ...rest
  } = props;

  const loading: "lazy" | "eager" | undefined = typeof lazyLoaded === "undefined" || lazyLoaded ? "lazy" : undefined;
  const transformed = transformImage(contentfulLink);

  return typeof transformed !== "string" ? (
    <picture css={attachPictureStyles} style={style}>
      {wrapToHtmlSourceElements(transformed).map(source => {
        return <source key={JSON.stringify(source)} srcSet={source.srcSetCssStr} type={source.type} />;
      })}

      <img
        src={transformed.fallback}
        {...rest}
        loading={loading}
        alt={alt}
        css={[
          css`
            position: absolute;

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          `,
          attachStyles,
        ]}
        style={
          {
            objectPosition: props.focalPointCssStr,
          } as any
        }
      />
    </picture>
  ) : null;
};
