import { css } from "@emotion/react";
import { HeaderCustomLinksNavT } from "@swo/storefront";
import React from "react";
import { CSS_MQ } from "../../../styles/breakpoints";
import { ImageWithFocalPoint } from "../../_shared-components/image-related/ImageWithFocalPoint";
import { LinksWithImages } from "./LinksWithImages";
import { Wrapper } from "./Wrapper";

type Props = {
  content: HeaderCustomLinksNavT;
};

export const CustomLinksUniversalElement = React.forwardRef<HTMLUListElement, Props>(({ content }, ref) => {
  const { links, image } = content;

  return (
    <div
      className="u-w-full u-flex u-flex-col u-jc-between u-grow so-scrollbar-y"
      css={css`
        ${CSS_MQ.atLeastMd} {
          overflow-x: hidden;
        }
      `}
    >
      <Wrapper>
        <LinksWithImages ref={ref} links={links} />
      </Wrapper>

      {image && (
        <ImageWithFocalPoint
          style={{ "--ar": image.image.width / image.image.height } as React.CSSProperties}
          image={image.image}
          focalPoint={image.focalPoint}
          requestedWidth={700}
          attachPictureStyles={css`
            position: relative;
            flex-shrink: 0;

            display: block;
            aspect-ratio: var(--ar);

            ${CSS_MQ.atMostSm} {
              margin-left: -40px;
              width: calc(100% + 60px);
            }
          `}
        />
      )}
    </div>
  );
});
