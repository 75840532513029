import React from "react";
import cn from "classnames";
import { HeaderHeadroom } from "./HeaderHeadroom";
import { MainHeaderProps } from "./props";
import { SwarovskiHeaderLogo } from "./SwarovskiHeaderLogo";
import { headerHeadroomStyles, leftPaneWrapperStyles, rightPaneWrapperStyles } from "./MainHeaderComponent";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";

type Props = Pick<MainHeaderProps, "floatingDisabled" | "headerHooks" | "links"> & {
  outletNearLogo: React.ReactNode;
  outletNavigation: React.ReactNode;
  navigationClassName: string | null;
  outletRightPane: React.ReactNode;
  className: string;
  isCustomShopMode: boolean;
  hasPaneContent: boolean;
  theme: NonNullable<MainHeaderProps["header"]>["otherData__legacy"]["theme"];
  
};

export const HeaderWrapper: React.FC<Props> = ({
  outletNearLogo,
  outletNavigation,
  navigationClassName,
  outletRightPane,
  className,
  isCustomShopMode,
  theme,
  floatingDisabled,
  headerHooks,
  links,
  hasPaneContent,
}) => {
  const commonStyles = css`
    &.tag-shop {
      --bg-color-second-pane: ${CSS_COLORS.White};
    }
    &.tag-content {
      --bg-color-second-pane: ${CSS_COLORS.Gray2024};
    }
    &.tag-green {
      --bg-color-second-pane: ${CSS_COLORS.Green};
    }
    &.tag-gray {
      --bg-color-second-pane: ${CSS_COLORS.Gray2024};
    }
  `;

  return (
    <HeaderHeadroom
      theme={theme}
      floatingDisabled={floatingDisabled}
      headerHooks={headerHooks}
      attachStyles={headerHeadroomStyles}
      className={cn(
        "js-header-headroom",
        {
          "custom-mode": isCustomShopMode,
          "has-opened-pane": hasPaneContent,
        },
        `variant-${theme}`,
      )}
    >
      <div className={cn(className, "js-header-headroom--left")} css={leftPaneWrapperStyles}>
        <SwarovskiHeaderLogo link={links.homepage} />

        {outletNearLogo}
      </div>

      <div className={cn(className, "js-header-headroom--right")} css={rightPaneWrapperStyles}>
        {outletRightPane}
      </div>

      <header
        css={[headerStyles, commonStyles]}
        className={cn("js-header-elem", { "custom-mode": isCustomShopMode }, `variant-${theme}`, navigationClassName)}
      >
        {outletNavigation}
      </header>
    </HeaderHeadroom>
  );
};

export const headerStyles = css`
  width: 100%;

  z-index: 100;
  display: flex;
  justify-content: center;

  --margin-top: 80px;

  --padding-top: 40px;
  @media (min-height: 780px) {
    --padding-top: 120px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-pinned-height);

    z-index: -1;
  }

  .headroom--unfixed & {
    &.variant-white {
      &:before {
        background: linear-gradient(white, transparent);
      }
    }

    &.variant-transparent-white {
      &:before {
        background: linear-gradient(#00000060, transparent);
      }
    }
  }

  .headroom--scrolled & {
    transition: background-color 0.3s linear;

    &.variant-white,
    &.variant-transparent-black,
    &.variant-transparent-white {
      background-color: rgba(255, 255, 255, 0.9);
      color: black;
    }

    &.custom-mode {
      background-color: rgba(255, 0, 0, 0.82);
      color: black;
    }
  }

  &.background-light {
    --icon-color: black;
  }

  &.background-white {
    --icon-color: black;
  }

  &.background-dark {
    --icon-color: white;
  }

  background-color: var(--header-variant-theme-background-color);
  color: var(--header-variant-theme-text-color);
`;
