import React from "react";

import { css } from "@emotion/react";

import { NewHeaderData, type TranslatedValue } from "@swo/storefront";
import { CSS_MQ } from "../../styles/breakpoints";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { LanguagePickerLocale } from "./LanguagePickerCountryOptionDisplay";
import { LanguagePickerCountryFlag } from "./LanguagePickerCountryFlag";
import { T } from "../T";

export const LanguagePickerComponent: React.FC<
  NewHeaderData["otherData__legacy"]["languagePicker"] & {
    otherCountriesLabel: TranslatedValue;
    compact: boolean;
  }
> = props => {
  const { countryBasedData, internationalData, otherCountriesLabel } = props;

  const sortedEshopLocales = [...countryBasedData].sort((a, b) => a.country.label.localeCompare(b.country.label));
  const sortedInternationsl = [...internationalData.languages].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div
      css={css`
        ${CSS_MQ.atMostSm} {
          margin-bottom: 20px;
        }
      `}
    >
      <ul css={listStyles} className={props.compact ? "compact" : ""}>
        {sortedEshopLocales.map(data => (
          <li
            css={css`
              display: grid;
              grid-template-columns: 38px 1fr;
              grid-template-rows: auto 1fr;

              color: ${CSS_COLORS.Black};
            `}
            key={data.country.isoCode}
          >
            <LanguagePickerLocale languages={data.languages} label={data.country.label}>
              <LanguagePickerCountryFlag
                countryIsoCode={data.country.isoCode}
                css={css`
                  color: inherit;
                `}
              />
            </LanguagePickerLocale>
          </li>
        ))}
      </ul>

      <hr css={separatorStyles} />

      {internationalData.languages.length > 0 ? (
        <div
          css={css`
            // display: grid;
            grid-template-columns: 38px 1fr;
            grid-template-rows: auto 1fr;

            color: ${CSS_COLORS.Black};
          `}
        >
          <LanguagePickerLocale
            languages={sortedInternationsl}
            label={
              <span className="u-mb-2">
                <T>{otherCountriesLabel}</T>
              </span>
            }
          />
        </div>
      ) : null}
    </div>
  );
};

const separatorStyles = css`
  height: 1px;
  width: 100%;
  max-width: 100px;

  border: none;
  background-color: ${CSS_COLORS.Black};

  margin-left: 38px;

  margin-top: 20px;
  margin-bottom: 30px;
  ${CSS_MQ.atLeastMd} {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const listStyles = css`
  display: grid;
  max-width: var(--max-container-width);

  ${CSS_MQ.atMostSm} {
    &:not(.compact) {
      width: 100%;
      grid-row-gap: 20px;
    }

    &.compact {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      grid-row-gap: 24px;
      grid-column-gap: 12px;
    }
  }

  ${CSS_MQ.atLeastMd} {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-row-gap: 24px;
    grid-column-gap: 12px;
  }

  ${CSS_MQ.atLeastLg} {
    // grid-auto-rows: 1fr;
    grid-auto-rows: auto;
  }
`;
