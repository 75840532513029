import { type State, initialState } from "./State";
import React from "react";
import { useToolbarState } from "./useToolbarState";

const ToolbarStateContext = React.createContext<{
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
}>({
  state: initialState,
  setState: () => {},
});

export function ToolbarStateProvider(props: { children: React.ReactNode }) {
  const { state, setState } = useToolbarState();

  return <ToolbarStateContext.Provider value={{ state, setState }}>{props.children}</ToolbarStateContext.Provider>;
}

export function useToolbarStateCtx() {
  return React.useContext(ToolbarStateContext);
}

export function useTranslationsStateCtx(): boolean {
  const { state } = useToolbarStateCtx();

  return state?.translationHints ?? false;
}
