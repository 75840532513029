import React from "react";

import { css, SerializedStyles } from "@emotion/react";
import { AssetWitSizeType, ImageWithFocalPointType } from "@swo/shared";
import { clamp } from "fp-ts/es6/Ord";
import { ord } from "fp-ts";
import { mathRound } from "../../../utils/js/missing-js-parts";
import { ImgWithObjectPosition } from "./ImgWithObjectPosition";

type ImageWithFocalPointProps = {
  image: {
    width: number;
    height: number;
    title: string;
    description?: string | undefined | null;
    url: string;
  };
  focalPoint: {
    x: number;
    y: number;
  };
  attachStyles?: SerializedStyles;
};

export const ImageWithFocalPoint: React.FC<
  ImageWithFocalPointProps & {
    requestedWidth: number;
    attachPictureStyles?: SerializedStyles;
    lazyLoaded?: "lazy" | "eager";
    noTransparentBackground?: boolean;
    style?: React.CSSProperties;
  }
> = props => {
  const { image, focalPoint, attachStyles, lazyLoaded } = props;
  const { url, title, width, height } = image;

  const focalPointObj = FocalPointPosition.create({ width, height }, focalPoint);

  return (
    <ImgWithObjectPosition
      style={props.style}
      contentfulLink={{
        url,
        requestedWidth: props.requestedWidth,
        noTransparentBackground: props.noTransparentBackground,
      }}
      alt={title}
      focalPointCssStr={focalPointObj.asCssString()}
      css={attachStyles}
      attachPictureStyles={props.attachPictureStyles}
      lazyLoaded={lazyLoaded}
    />
  );
};

const percentageClamp = clamp(ord.ordNumber)(0, 100);

export class FocalPointPosition {
  private constructor(
    private readonly focalPoint: { positionX: number; positionY: number },
    private readonly image: { width: number; height: number },
  ) {}

  static create(image: { width: number; height: number }, focalPoint: { x: number; y: number } | undefined) {
    const positionX = image.width > 0 && focalPoint ? FocalPointPosition.round(focalPoint.x / image.width) : 50;
    const positionY = image.height > 0 && focalPoint ? FocalPointPosition.round(focalPoint.y / image.height) : 50;

    return new FocalPointPosition({ positionX, positionY }, image);
  }

  private static round(size: number) {
    return percentageClamp(mathRound(size * 100, 1));
  }

  asCssString(): string {
    return `${this.focalPoint.positionX}% ${this.focalPoint.positionY}%`;
  }

  imageInversedAspectRatio(): number {
    return this.image.height / this.image.width;
  }
}

export function getFocalPoint(asset: ImageWithFocalPointType): FocalPointPosition | undefined {
  return !!asset.image.width && !!asset.image.height && asset.focalPoint
    ? FocalPointPosition.create({ width: asset.image.width, height: asset.image.height }, asset.focalPoint)
    : undefined;
}

export const centeredFocalPoint = FocalPointPosition.create({ width: 100, height: 100 }, { x: 50, y: 50 });
