import React, { useContext, useMemo } from "react";
import { StoreAction } from "../app-store/actions";
import { AppStoreState } from "../app-store/app-store-ctx-provider";
import { UserAspectPublicAction } from "../aspects/user-aspect/public-action";

export type StoreWithDispatch = {
  state: AppStoreState;
  dispatch: React.Dispatch<StoreWithDispatchAction>;
};

export type StoreWithDispatchAction = StoreAction | UserAspectPublicAction;

export const AppCtx = React.createContext<StoreWithDispatch>(undefined as any);

export function useStore(): StoreWithDispatch {
  const ctx = useContext(AppCtx);

  return useMemo(() => ctx, [JSON.stringify(ctx.state), ctx.dispatch]);
}
