import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { nonEmpty } from "../../../../../utils/fp/small-fp-utils";

export type ProductNameCaptureT = { text: string; dontTransform: boolean };

export function captureProductNamesInText(text: string): Array<ProductNameCaptureT> {
  const ret: Array<ProductNameCaptureT> = pipe(
    productRegExps.map(re => text.match(re)),
    array.filterMap(a => (a && typeof a.index === "number" ? option.some({ match: a, index: a.index }) : option.none)),
    array.head,
    option.map(({ match, index }) => {
      const m = match[0];
      const matchLength = m.length;

      const b = text.slice(0, index);
      const e = text.slice(index + matchLength);

      return [
        b && b.length > 0 ? { text: b, dontTransform: false } : null,
        m && m.length > 0 ? { text: m, dontTransform: true } : null,
        e && e.length > 0 ? { text: e, dontTransform: false } : null,
      ].filter(nonEmpty);
    }),
    option.getOrElseW(() => [{ text: text, dontTransform: false }]),
  );

  return ret.flatMap(r => (r.dontTransform ? r : captureDDxDDschema(r.text)));
}

const productRegExps = [
  /[XZ]\d+[A-Za-z()\+?]*/,
  /d[SG]/,
  /CL Companion Habicht/,
  /CL Companion/,
  /CL Pocket/,
  /NL Pure/,
  /EL Range/,
  /ST Vista/,
  /ATX Interior/,
  /Habicht/,
  /ST Vista/,
  /tM 35/,
  /MY Junior/,
  /AX Visio/,
];

const xBetweenNumbers = /\d+x\d+/;

function captureDDxDDschema(text: string): Array<ProductNameCaptureT> {
  const res = text.match(xBetweenNumbers);

  if (res) {
    const { 0: m, index } = res;

    if (typeof index === "number") {
      const matchLength = m.length;

      const b = text.slice(0, index);
      const e = text.slice(index + matchLength);

      return [
        b && b.length > 0 ? { text: b, dontTransform: false } : null,
        m && m.length > 0 ? { text: m, dontTransform: true } : null,
        e && e.length > 0 ? { text: e, dontTransform: false } : null,
      ].filter(nonEmpty);
    }
  }

  return [{ text: text, dontTransform: false }];
}
