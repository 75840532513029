import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import React from "react";
import { createPngLink, createWebpLink } from "./createJpgAndWebpLinks";
import { FocalPointPosition } from "./ImageWithFocalPoint";

const regex = /^\d+px$/;

export const ContentfulImage2: ReactTFC<
  {
    contentfulUrl: string | undefined;
    quality?: number;
    withTransparency: boolean;
    cropping:
      | {
          focalPoint: {
            x: number;
            y: number;
          };
          image: {
            width: number;
            height: number;
          };
        }
      | undefined;
  } & Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src" | "srcSet">
> = props => {
  const { contentfulUrl: src, withTransparency, cropping, sizes, ...restProps } = props;

  const linkCreator = withTransparency
    ? (src: string, size: number) => createWebpLink(src, size, props.quality || 80)
    : (src: string, size: number) => createPngLink(src, size, props.quality || 80);

  const simpleSizesParam = sizes ? regex.test(sizes) : false;

  function getSrcSet(src: string): string {
    let widths = [150, 300, 600, 1000, 1600, 2200];

    if (simpleSizesParam && sizes) {
      let lastMatchingWidth = widths.findLastIndex(w => w <= parseInt(sizes));
      if (lastMatchingWidth < 0) {
        lastMatchingWidth = 1;
      }

      widths = widths.filter((_, idx) => idx <= lastMatchingWidth + 1);
    }

    return widths.map(size => `${linkCreator(src, size)} ${size}w`).join(", ");
  }

  const fp = cropping ? FocalPointPosition.create(cropping.image, cropping.focalPoint).asCssString() : undefined;

  return (
    <img
      src={src}
      alt={""}
      srcSet={src ? getSrcSet(src) : undefined}
      {...restProps}
      style={fp ? { objectPosition: fp, objectFit: "cover" } : undefined}
      sizes={sizes}
    />
  );
};
