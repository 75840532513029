import { PickersPaneContentState, STANDARD_PROPS } from "./PaneContentState";
import { Segments } from "./props";
import { segmentPickerState } from "./useActions";
import { HeaderT } from "../HeaderT";
import { NewHeaderData } from "@swo/storefront";

export function createStates(
  segments: Segments | null,
  cart: { link: string; data: NewHeaderData["cart"] } | null,
  t: HeaderT,
) {
  const dealersState: PickersPaneContentState = {
    tag: "dealer-locator",
    title: t("navigation.dealerLocatorTitle"),
    ...STANDARD_PROPS,
  };

  const searchState: PickersPaneContentState = {
    tag: "search",
    title: t("navigation.searchTitle"),
    width: "standard",
    theme: "light",
  };

  const accountState: PickersPaneContentState = {
    tag: "account",
    title: t("navigation.accountTitle"),
    ...STANDARD_PROPS,
    theme: "white",
  };

  const segmentsState: PickersPaneContentState | null =
    segments !== null ? segmentPickerState(segments, t).content : null;

  const cartState: PickersPaneContentState | null = cart
    ? {
        tag: "cart",
        cartLink: cart.link,
        cartData: cart.data,
        title: t("navigation.cartTitle"),
        ...STANDARD_PROPS,
        theme: "white",
      }
    : null;

  const languagePickerStateState: PickersPaneContentState = {
    tag: "language-picker",
    title: t("navigation.languagePickerTitle"),
    width: "wide",
    theme: "light",
  };

  return { dealersState, searchState, accountState, segmentsState, cartState, languagePickerStateState };
}
