import { z } from "zod";

export type State = z.infer<typeof stateSchema>;

export const stateSchema = z.object({
  opened: z.boolean(),
  componentLinks: z.boolean(),
  translationHints: z.boolean(),
  errorsStrategy: z.union([z.literal("fail-fast"), z.literal("fine-grained")]),
});

export type ErrorsStrategy = z.infer<typeof stateSchema.shape.errorsStrategy>;
export const errorsStrategies: Array<ErrorsStrategy> = stateSchema.shape.errorsStrategy._def.options.map(_ => _.value);

export const initialState: State = {
  opened: true,
  componentLinks: false,
  translationHints: false,
  errorsStrategy: "fine-grained",
};

export const CookieNames = {
  password: "cms-password",
  state: "cms-state",
};
