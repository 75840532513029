import React, { useCallback, useRef, useState } from "react";

import { css } from "@emotion/react";

import { ReactComponent as SearchIcon } from "../../assets/icons/Icon/DKT/Search black.svg";
import { CSS_MQ } from "../../styles/breakpoints";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { FONTS, fontSize } from "../../styles/fonts";
import { useNavigate } from "react-router";
import { useOnRouteChange } from "../../hooks/use-on-route-change";

function useTrimmedState(initialState: string) {
  const [value, setValue] = useState(initialState);
  const setValueTrimmed = useCallback((a: string) => setValue(a), []);

  return [value, setValueTrimmed] as const;
}

export const MainNavigationSearchComponent_Old: React.FC<{ searchText: string; searchPageUrl: string }> = props => {
  const [value, setValue] = useTrimmedState("");
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const params = value
      ? new URLSearchParams({
          q: value,
        })
      : undefined;

    void navigate(
      {
        pathname: props.searchPageUrl,
        search: params ? `?${params.toString()}` : undefined,
      },
      {
        state: {
          scrollToTop: true,
        },
      },
    );
  };

  useOnRouteChange(() => {
    setValue("");
  }, []);

  const formStyles = css`
    display: flex;
    background: white;

    width: 100%;
    border-bottom: 1px solid transparent;
    background: transparent;

    ${CSS_MQ.atLeastMd} {
      max-width: 400px;

      border-bottom: 1px solid ${CSS_COLORS.Black};
    }

    height: 52px;
    align-items: center;

    border-bottom: 1px solid ${CSS_COLORS.Black};
    padding-bottom: 1px;

    &:focus-within {
      border-bottom: 2px solid ${CSS_COLORS.Green};
      padding-bottom: 0;
    }
  `;

  const inputStyles = [
    fontSize.x2,
    FONTS.MinervaModernItalic500,
    css`
      border: none;

      height: 52px;
      width: 100%;

      background: transparent;
      color: ${CSS_COLORS.Black};

      padding-left: 18px;

      &::placeholder {
        color: ${CSS_COLORS.Black};

        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }

      &:focus {
        outline: none !important;
      }
    `,
  ];
  return (
    <form onSubmit={onSubmit} css={formStyles}>
      <button
        type={"submit"}
        css={css`
          height: 52px;
          width: 52px;

          order: 2;
        `}
      >
        <SearchIcon
          css={css`
            width: 20px;
            height: 20px;
            color: ${CSS_COLORS.Black};
          `}
        />
      </button>

      <input value={value} onChange={e => setValue(e.target.value)} placeholder={props.searchText} css={inputStyles} />
    </form>
  );
};
